import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import 'react-toastify/dist/ReactToastify.min.css';
import './index.css';
import { ToastContainer } from 'react-toastify';
import App from './App';
import reportWebVitals from './reportWebVitals';
// In index.js or index.tsx

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <ToastContainer
      position="top-right"
      newestOnTop={false}
      limit={1}
      rtl={false}
      pauseOnFocusLoss
      draggable
      closeOnClick
      autoClose={10000}
    />
    <QueryClientProvider client={queryClient}>
      <App />
      <ReactQueryDevtools initialIsOpen />
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
