import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { useMutation } from 'react-query';
import axios from 'axios';
import { toast } from 'react-toastify';
import { InputText, Button } from '../components';
import { ROUTE } from '../routes';
import AuthLayout from '../components/Layout/AuthLayout';

interface IForgotPassword {
    email: string;
}

const BASE_URL = process.env.REACT_APP_BASE_URL;
const NODE_ENV = process.env.REACT_APP_NODE_ENV;

const resetPassword = async (payload: IForgotPassword): Promise<any> => {
  const response = await axios({
    method: 'POST',
    url: `${BASE_URL}/auth/forgot-password`,
    responseType: 'json',
    data: payload,
  });
  return response;
};

const ResetPassword: React.FC = () => {
  const { mutate } = useMutation(resetPassword, {
    onSuccess(res) {
      const { data, message } = res.data;
      const userId = data?.user_id;
      const redirectUrl = NODE_ENV === 'staging'
        ? `/mis${ROUTE.SET_NEW_PASSWORD}?user_id=${userId}`
        : `${ROUTE.SET_NEW_PASSWORD}?user_id=${userId}`;

      const toastId = toast.success(message, {
        onClose: () => {
          window.location.href = redirectUrl;
        },
      });

      setTimeout(() => {
        toast.dismiss(toastId);
      }, 5000);
    },
    onError(error: any) {
      const errorMessage = error?.response?.data?.message
        || 'An unexpected error occurred. Please try again later.';

      toast.error(errorMessage, {
        autoClose: 5000,
      });
    },
  });

  React.useEffect(() => () => toast.dismiss(), []);

  const {
    handleSubmit, touched, errors, handleChange, handleBlur, values,
  } = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Invalid email address.')
        .required('Email is required.'),
    }),
    onSubmit: (submittedData) => {
      mutate(submittedData);
    },
  });

  return (
    <AuthLayout
      navLinks={(
        <Link
          to={ROUTE.HOME}
          className="text-blue bg-white hover:bg-blue-50 font-medium
          rounded text-lg px-5 py-2.5 me-2 mb-2 focus:outline-none"
        >
          Login
        </Link>
            )}
    >
      <p className="text-center text-2xl md:text-3xl text-blue my-4 md:my-6">
        Reset Password.
      </p>
      <p className="text-left text-sm md:text-base text-gray-600 my-2 md:my-4">
        Please enter your email to receive a verification code for password reset.
      </p>

      <form className="flex flex-col pt-3" onSubmit={handleSubmit}>
        <InputText
          type="email"
          label="Email"
          id="email"
          placeholder="doe@bkth.rw"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          errorMsg={touched.email && errors.email ? errors.email : ''}
        />
        <Button type="submit" variant="primary" className="mt-4 md:mt-6">
          Send
        </Button>
      </form>
    </AuthLayout>
  );
};

export default ResetPassword;
