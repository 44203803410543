/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/button-has-type */
import React from 'react';
import PulseLoader from 'react-spinners/PulseLoader';

type Props = {
  children: React.ReactNode;
  type: 'button' | 'submit' | 'reset' | undefined;
  variant?: 'primary' | 'secondary' | 'tertiary';
  onClick?: React.EventHandler<React.FormEvent>;
  className?: string;
  size?: 'large' | 'medium' | 'normal' | 'small';
  disabled?: boolean;
  loading?: boolean;
  [x: string]: any;
};

const Button: React.FC<Props> = ({
  children,
  type = 'button',
  variant = 'primary',
  onClick,
  className = '',
  size = 'normal',
  disabled = false,
  loading = false,
  ...rest
}: Props) => {
  const getVariantClasses = (): string => {
    switch (variant) {
      case 'secondary':
        return `text-green bg-transparent border border-solid border-green 
                hover:bg-green-100 active:bg-green font-bold uppercase
                rounded outline-none focus:outline-none
                ease-linear transition-all duration-150
                ${disabled ? 'border-gray text-gray hover:border-gray hover:text-gray' : ''}`;
      case 'tertiary':
        return `text-green background-transparent font-bold uppercase rounded
                outline-none focus:outline-none ease-linear transition-all
                duration-150 hover:bg-green-100 active:bg-green-alert
                ${disabled ? 'bg-gray-light text-gray hover:bg-gray-light' : ''}`;
      case 'primary':
      default:
        return ` active:bg-green font-bold uppercase
                hover:shadow-lg outline-none focus:outline-none 
                ease-linear transition-all duration-150 rounded
                ${disabled ? 'bg-green opacity-50 text-white cursor-not-allowed hover:shadow-none' : 'bg-green text-white cursor-pointer'}`;
    }
  };

  const getSizeClasses = (): string => {
    switch (size) {
      case 'large':
        return 'text-base px-8 py-3';
      case 'medium':
        return 'text-sm px-6 py-2.5'; // Standard medium size
      case 'small':
        return 'text-xs px-4 py-2';
      case 'normal':
      default:
        return 'text-sm px-6 py-3';
    }
  };

  const margin = size === 'large' ? 3 : size === 'small' ? 1 : 2;
  const color = variant === 'primary' ? '#fff' : '#00ff95';

  return (
    <button
      {...rest}
      type={type}
      className={`${getVariantClasses()} ${getSizeClasses()} ${className}`}
      onClick={onClick}
      disabled={disabled}
      aria-hidden="true"
    >
      {loading ? <PulseLoader color={color} size={7} margin={margin} /> : children}
    </button>
  );
};

Button.defaultProps = {
  onClick: () => [{}],
  className: '',
  size: 'normal',
  variant: 'primary',
};

export default Button;
